//- 手機橫向時出現cover
$(window).on('resize',function(){
  $(()=>{
    const _w=$(window).outerWidth(),
          _h=$(window).outerHeight(),
          ratio=_w/_h,
          $cover=$('.landscape-cover')
    // console.log('w=',_w)
    // console.log('h=',_h)
    // console.log('ratio=',ratio)
    // ratio越大越扁
    if(_h<600 && ratio>1.76){
      $cover.css('display','block')
    }else{
      $cover.css('display','none')
    }
  })
}).resize()