
//--info區塊的內容
//info_project_name:'大地綠建材',
//info_client_name:'大地綠建材',
//info_job_part:'視覺設計 / 前端開發',
//info_tech_support:'技術夥伴：古洛迪數位行銷',
//--是否有對外的連結
//link:'https://google.com',
//--桌機截圖(適用桌機版&手機版)
//imgSrc:'images/web/web01.jpg',
//--手機截圖(筆電旁的那個, 不是指裝置上的手機版)
//imgSrc_m:'images/web/cat.jpg',
//--下方滑動的專案名稱
//nav_txt:'大地綠建材',
//--是否有fancybox的放大鏡
//view:'images/web/preview/web01.jpg',
//--桌機版的fancybox啟動
//gallery_num:'01',
//--手機版的fancybox啟動
//gallery_num_m:'01_m'

var webObj=[
  {
    info_project_name:'',
    info_client_name:'',
    info_job_part:'',
    info_tech_support:'',
    link:'',
    imgSrc:'images/web/web00.jpg',
    imgSrc_m:'images/web/web00-m.jpg',
    nav_txt:'',
    view:'',
    gallery_num:'00',
    gallery_num_m:'00_m'
  },
];

(function web(){
  var webLen=webObj.length,
      $slick=document.querySelector('.slick'),
      $slick_m=document.querySelector('.slick.for-m'),
      str='',
      str_link='',
      str_group='',
      str_view='',
      str_view_m='',
      str_m='',
      $sliderNav=document.querySelector('.slider-nav'),
      srtNav='';

  for(var i=0;i<webLen;i++){
    // 有view就有放大鏡可瀏覽單圖，此處會出現放大鏡icon以及觸發fancybox的link
    if(webObj[i].view){
      str_view=`<li class="view"><a href="${webObj[i].view}" data-fancybox="web${webObj[i].gallery_num}"><i class="fas fa-search-plus"></i></a></li>`
      str_view_m=`<li class="view"><a href="${webObj[i].view}" data-fancybox="web${webObj[i].gallery_num_m}"><i class="fas fa-search-plus"></i></a></li>`
      
    }else{
      str_view=''
      str_view_m=''
    }
    // 有link就有連結icon可以外連
    if(webObj[i].link){
      str_link=`<li class="link"><a href="${webObj[i].link}" target="_blank"><i class="fas fa-link"></i></a></li>`
    }else{
      str_link=''
    }
    // pc、mobile共同部份
    // <li>${webObj[i].info_client_name}</li>
    str_group=`          
        <li class="info"><a href="javascript:void(0);"><i class="fas fa-question"></i></a></li>
        `+str_link+`              
      </ul>
      <ul class="info-content">
        <li>${webObj[i].info_project_name}</li>
        <li>${webObj[i].info_job_part}</li>
        <li>${webObj[i].info_tech_support}</li>
      </ul>`;
    // PC
    str+=
    `<li>
      <ul class="info-box">`
      +str_view
      +str_group+
    `
      <div class="slick-img">
        <div class="loading_notice">資料載入中...</div>
        <div class="imgs">
          <img src="${webObj[i].imgSrc}" class="large"/>
          <img src="${webObj[i].imgSrc_m}" class="small"/>
        </div>
        <img src="images/web/web-loading.png" class="loading"/>
      </div>
    </li>`;
    //Mobile
    str_m+=
    `<li>
      <ul class="info-box">`
      +str_view_m
      +str_group+
    `
      <div class="slick-img">
        <div class="loading_notice">資料載入中...</div>
        <img src="${webObj[i].imgSrc}"/>
        <img src="images/web/web-loading-m.jpg" class="loading"/>
      </div>
    </li>`;
    $slick_m.innerHTML=str_m;
    $slick.innerHTML=str;
    // slick nav的slider
    srtNav+=`<a href="javascript:void(0);">${webObj[i].nav_txt}</a>`
    $sliderNav.innerHTML=srtNav;
  }
})()
