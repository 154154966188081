// Html5 動態素材
var bnObj=[
  {
    img:'images/banner/bn000.jpg',
    description:'',
    support:'',
    link:'',
    nonLink:''
  },
  
];
//全媒體靜態素材
var bnObj2=[
  {
    img:'images/banner/bn000.jpg',
    description:'',
    support:'',
    link:'',
    nonLink:''
  },

];

(function banner(){
  //html5 banner
  var bnLen=bnObj.length,
      $html5=document.querySelector('.html5'),
      str='';
  for(var i=0;i<bnLen;i++){
    // 主要slider的bn圖
    str+=`
    <li>
      <div class="loading_notice">資料載入中...</div>
      <span>        
        <a href="${bnObj[i].link}" target="_blank" class="sample-btn ${bnObj[i].nonLink}">
          <i class="fas fa-lightbulb"></i><span class="tag">動態展示</span>
        </a>
      </span>
      <img src="${bnObj[i].img}"/>  
      <span class="description">${bnObj[i].description}<b>${bnObj[i].support}</b></span>   
    </li>`;
    $html5.innerHTML=str;
  }
  //靜態banner
  var bnLen2=bnObj2.length,
  $static=document.querySelector('.static'),
  str2='';

  for(var j=0; j<bnLen2; j++) {

    // 主要slider的bn圖
    str2+=` <li>
    <div class="loading_notice">資料載入中...</div>
    <img src="${bnObj2[j].img}"/>
    <span class="description">${bnObj2[j].description}<b>${bnObj2[j].support}</b></span>
    </li>`;
    $static.innerHTML=str2;
  }


})()
const $tab_bar = $('.tab-bar')
const $li = $tab_bar.find('li')
const $tab_content = $('.tab-content')
const $group1 = $tab_content.find('.group1')

$li.click(function(e) {
  e.preventDefault();
  let _index = $(this).index()

  $li.removeClass('selected')
  $(this).addClass('selected')

  $group1.hide()
  $group1.eq(_index).show()
  $('.html5')[0].slick.refresh()
  $('.static')[0].slick.refresh()
  // console.log('xxx='+$('.slick-track')[0])
  // console.log('ff='+_index)
})
// $(window).on('load',function() {
//   // console.log('ssss')
//   setTimeout(() => {
//     $group1.eq(0).show().siblings().hide()
//   }, 300)

// });