//- Granim kv背景色漸變設定
function reportWindowSize() {
  var src=""
  var color_arr=[
    ['#2e2213', '#2d0935'],
    ['#191922', '#252525'],
    ['#2e2a29', '#022a42']
  ]
  if(window.innerWidth<=767){
    src="images/kv-767.jpg"
    color_arr=[
      ['#3e2f2f', '#2d1843'],
      ['#191922', '#252525'],
      ['#203232', '#002b3d']
    ]
  }else if(window.innerWidth>767 && window.innerWidth<960){
    src="images/kv-960.jpg"
  }else if(window.innerWidth>960 && window.innerWidth<1280){
    src="images/kv-1280.jpg"
  }else{
    src="images/kv.jpg"
  }
  var granimInstance = new Granim({
      element: '#granim-canvas',
      direction: 'top-bottom',
      isPausedWhenNotInView: true,
      transitionSpeed: 100,
      image : {
        source: src,
        position:['center','bottom'],
        blendingMode: 'screen'
      },
      states : {
        "default-state": {
          gradients: color_arr,
          transitionSpeed: 2000
        }
      }
  });
}
$(window).on('resize',function(){
  reportWindowSize()
}).resize()
