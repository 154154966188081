//nav.js start
$(function(){
  var $switch=$('.switch'),
  $subMenu=$('.sub-menu'),
  _w=$(window).outerWidth(true)

  //左側選單收闔
  $switch.on('click',function(e){
    e.preventDefault();
    // console.log('vvv')
    if($(this).hasClass('open')){
      // console.log('關起')
      $(this).removeClass('open');
      $subMenu.stop().animate({
        left:-246
      },50)
    }else{
      // console.log('打開')
      $(this).addClass('open');
      $subMenu.stop().animate({
        left:0
      },50)
    }
  })

  // PC版滑入開關會自動開啟選單, 不設_w的話在pad等裝置會造成在選單外點擊到, 要再打開選單要點兩下的bug
  if(_w>1400){
    // console.log('mouseenter')
    $switch.on('mouseenter',function(){
      
      $switch.addClass('open');
      $subMenu.stop().animate({
        left:0
      },50)
    })
  }

  // 離開選單範圍, 也就是碰到main的範圍時收回選單
  $('.main').on('mouseenter',function(){
    $switch.removeClass('open');
    $subMenu.stop().animate({
      left:-246
    },50)
  })

  //選單共用程式(左側、kv四鈕、右側圓鈕)
  function nav_click(_index){
    switch (_index){
      case 0:
        $.scrollify.move("#1");
        break;
      case 1:
        $.scrollify.move("#2");
        break;
      case 2:
        $.scrollify.move("#3");
        break;
      case 3:
        $.scrollify.move("#4");
        break;
      default:
        $.scrollify.move("#5");
        break;
    }    
  }

  var $subMenu=$('.sub-menu'),
      $subMenuUl=$subMenu.find('ul'),
      $subMenuLi=$subMenuUl.find('li'),
      $kvMenu=$('.kv-menu'),
      $kvMenuLi=$kvMenu.find('li'),
      $content=$('.content'),
      $section=$content.find('section'),
      _index=0,
      $home=$('.home');
  //最右邊懸浮圓鈕
  const $link=$('.link');
  const $jump=$link.find('.jump')

  // 是否為行動裝置之判別法2
  function isMobile() {
    try{ document.createEvent("TouchEvent"); return true; }
    catch(e){ return false;}  
  }
  if(isMobile()){
    console.log('這是行動裝置')
    // kv四大鈕
    $kvMenuLi.on('click',function(e) {
      e.preventDefault();
      _index=$(this).index()+1
      // _scr=$section.eq(_index).offset().top;
      // console.log(_scr)
      $('html,Body').stop().animate({
        scrollTop:$section.eq(_index).offset().top
      })
      // console.log(_scr)
    });
    // 左側副選單
    $subMenuLi.on('click',function(e) {
      e.preventDefault();
      _index=$(this).index()
      // _scr=$section.eq(_index).offset().top;
      // console.log(_scr)
      $('html,Body').stop().animate({
        scrollTop:$section.eq(_index).offset().top
      })
      // console.log(_scr)
    });
  }else{
    console.log('這不是行動裝置')
    // kv四大鈕
    $kvMenuLi.on('click',function(e) {
      e.preventDefault();
      _index=$(this).index();
      // console.log(_index)
      //因為區塊有5個, kv鈕只有四個, 所以不能用nav_click(_index)
      switch (_index){
        case 0:
          $.scrollify.move("#2");
          break;
        case 1:
          $.scrollify.move("#3");
          break;
        case 2:
          $.scrollify.move("#4");
          break;
        default:
          $.scrollify.move("#5");
          break;
      } 
    });
    // 左側副選單
    $subMenuLi.on('click',function(e) {
      e.preventDefault();
      _index=$(this).index()
      //console.log(_index)
      nav_click(_index)
    });
  }

  //左側直行思潮字樣
  $(".logo-nav").on('click',function(e){
    e.preventDefault();    
    $.scrollify.move("#1");
  })

  $home.on('click',function(e){
    $.scrollify.move("#1");
    // console.log('a')
  })
  //視窗滑動時讓大側圓鈕自動依單元變色
  $jump.eq(0).addClass('selected');
  $jump.eq(0).siblings().removeClass('selected');
  $(window).on('scroll',function(){
    const scrollY = $(this).scrollTop();
    $section.each(function(){
      //console.log($(this).index())
      const sectionTop = $(this).offset().top;
      const sectionBottom = sectionTop + $(this).outerHeight();
      let i=$(this).index()
  
      // 如果窗口滚动位置在该区块范围内
      if (scrollY >= sectionTop && scrollY < sectionBottom) {
          $jump.eq(i).addClass('selected').siblings().removeClass('selected');
      }
      
    })
  })
  //最右邊懸浮圓鈕按下啟動scrollify
  $('.jump').on('click',function(e) {
    e.preventDefault();
    _index=$(this).index();
    nav_click(_index)
  });


  
    // 是否為行動裝置之判別法1
  // function isMobileDevice(){
  //   var mobileDevice = ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone']
  //   var isMobileDevice=false
  //   for(var i=0;i<mobileDevice.length;i++){
  //     if(navigator.userAgent.match(mobileDevice[i])){
  //       isMobileDevice=true
  //     }
  //   }
  //   return isMobileDevice
  // }
  
  // isMobileDevice();

  // if(isMobileDevice()){
  //   // console.log('這是行動裝置')
  // }else{
  //   // console.log('這不是行動裝置')
  //   // PC版滑入開關會自動開啟選單
  //   $switch.on('mouseenter',function(){
  //     // console.log('mouseenter')
  //     $switch.addClass('open');
  //     $subMenu.stop().animate({
  //       left:0
  //     },50)
  //   })
  // }

  // 是否為行動裝置之判別法2
  // function isMobile() {
  //   try{ document.createEvent("TouchEvent"); return true; }
  //   catch(e){ return false;}  
  // }
  // if(isMobile()){
  //   console.log('這是行動裝置')
  // }else{
  //   console.log('這不是行動裝置')
  //   // PC版滑入自動開啟
  //   $switch.on('mouseenter',function(){
  //     console.log('mouseenter')
  //     $switch.addClass('open');
  //     $subMenu.stop().animate({
  //       left:0
  //     })
  //   })
  // }

})

//nav.js end