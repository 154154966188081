
var dmObj=[
  {
    previewImg:'images/dm/dm00.jpg',
    previewImg_m:'images/dm/dm00-m.jpg',
    largeImg:'',   
    project_category:'',
    project_name:'',
    gallery_num:'00'
  },
];

(function dm(){
  var dmLen=dmObj.length,
      $slick3=document.querySelector('.slick3'),
      str='';

  for(var i=0;i<dmLen;i++){
    // 主要slider的dm圖
    str+=`
    <li>
      <div>
        <div class="loading_notice">資料載入中...</div>
        <img src="${dmObj[i].previewImg}" class="for-pc">
        <img src="${dmObj[i].previewImg_m}" class="for-m">
      </div>
      <div>
        <span>${dmObj[i].project_name}</span>
        <span class="project-category">${dmObj[i].project_category}</span>
        <a href="${dmObj[i].largeImg}" data-fancybox="dm${dmObj[i].gallery_num}">
          <i class="fas fa-search-plus"></i><span class="tag">完整內容</span>
        </a>
      </div>
    </li>`;
    $slick3.innerHTML=str;
  }
})()



