//mqdia-set.js start

$(window).on('resize',function(){
    const _w=$(window).outerWidth(),
          _h=$(window).outerHeight(),
          ratio=_w/_h
    // console.log('w=',_w)
    // console.log('h=',_h)
    // console.log('ratio=',ratio)
  
  //螢幕太長就隱藏四鈕
  if(ratio<1){
    $('.kv-menu').css('display','none');
  }else{
    $('.kv-menu').css('display','flex');
  }
  //
  if(_w > 1280 && _h < 700){
    //console.log('w>1280/h<700')
    // $('.kv-menu').css('display','none');
    // $('.site-name').css('display','none');
  }else if(_w < 1280 && _h < 550){
    //console.log('w<1280/h<550')
    // $('.kv-menu').css('display','none');
    // $('.site-name').css('display','none');
    $('.title').css('top','-25px');
    $('.surfing').css('top','35%');
  }else if(_w < 600){
    //console.log('w<600')
    // $('.kv-menu').css('display','none');
    // $('.site-name').css('display','none');
    $('.surfing').css('top','50%');
  }else if(_w < 767){
    //console.log('w<767')
    // $('.site-name').css('display','none');    
  }else if(_h < 800){
    //console.log('h<800')
    $('ul.kv-txt').find('li').css('font-size','40px');
  }else{
    // $('.kv-menu').css('display','flex');
    // $('.site-name').css('display','block');
    $('ul.kv-txt').find('li').css('font-size','50px');
  }
}).resize()

//media-set.js end