//copytext.js start
$(function(){
  window.Clipboard = (function(window, document, navigator) {
    var textArea,
    copy;

    function isOS() {
      return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
      textArea = document.createElement('textArea');
      textArea.value = text;
      document.body.appendChild(textArea);
    }

    function selectText() {
      var range,
      selection;

      if (isOS()) {
        range = document.createRange();
        range.selectNodeContents(textArea);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
    }

    function copyToClipboard() {
      document.execCommand("Copy");
      document.body.removeChild(textArea);
    }

    copy = function(text) {
      createTextArea(text);
      selectText();
      copyToClipboard();
    };

    return {
      copy: copy
    };
  })(window, document, navigator);

  $(".copy_coupon").on("click", function(e) {
      e.preventDefault();
      var $this = $(this),
      value = $this.prev("input").val();
      window.Clipboard.copy(value);
  });
})
//copytext.js end
